import { BaseUserWithRole, UserRegistrationStatus } from '@/models/User';
import { status as ProjectStatus } from '@/views/project-forum/models/ProjectForum';

export interface GuestCompanyInformation {
  id: string;
  name: string;
  rootUserId: string;
}
export enum CompanyGuestStatus {
  ACTIVE = 'active',
  DEACTIVATED = 'deactivated',
  INVITED = 'invited'
}

export interface InviteCompanyGuestRequest {
  firstName: string;
  lastName: string;
  email: string;
  message: string;
  projectList: string[];
}

export interface CompanyGuestProject {
  id: string;
  title: string;
  created: string;
  status: ProjectStatus;
}

export interface CompanyGuestListResponse extends Omit<BaseUserWithRole, 'userId' | 'company' | 'roles' | 'companyRole'> {
  id: string;
  inviteId?: string;
  memberSince: string;
  reputationPoints: number;
  projects?: CompanyGuestProject[];
  userRegistrationStep: UserRegistrationStatus;
  userRegistrationStatus: UserRegistrationStatus;
  active?: boolean;
  company: GuestCompanyInformation;
}

import { MaybeRef } from '@vueuse/core';
import { BaseUserWithRole, UserRole, UserType } from '@/models/User';
import { computed, Ref, unref } from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import { getUserType, userNames } from '@/utils/user';

const useUser = (_user:MaybeRef<BaseUserWithRole | null | 'self'>) => {
  const store = useStore();
  const { tUserType, tUserTypeFallBack } = useI18n();

  const user = computed(() => {
    const u = unref(_user);
    if (u === 'self') return store.getters.user;
    return u;
  });

  const type = computed(() => getUserType(user.value));

  const hasRole = (role: UserRole) => !!unref(user)?.roles?.includes(role);

  const isCustomer = computed(() => type.value === UserType.COMPANY);
  const isEmployee = computed(() => type.value === UserType.EMPLOYEE);
  const isGuest = computed(() => type.value === UserType.GUEST);
  const isAdmin = computed(() => hasRole('ADMIN'));

  const isFreelancer = computed(() => !isCustomer.value && !isEmployee.value && !isGuest.value);

  const displayName = computed(() => {
    const u = unref(user);
    if (isCustomer.value) return u?.company?.name ?? '';
    return `${u?.firstname} ${u?.lastname}`;
  });

  const informalName = computed(() => {
    const u = unref(user);
    if (isCustomer.value) return u?.company?.name ?? '';
    return u?.firstname ?? '';
  });

  const nameGenitive = (name:Ref<string>) => computed(() => {
    if (name.value.match(/(s|ß|x|z|tz)$/i)) {
      return `${name.value}'`;
    }
    return `${name.value}s`;
  });

  const displayNameGenitive = nameGenitive(displayName);
  const informalNameGenitive = nameGenitive(informalName);

  const names = computed(() => ({
    ...userNames(user.value),
    displayName: displayName.value,
    informalName: informalName.value,
    informalNameGenitive: informalNameGenitive.value,
    displayNameGenitive: displayNameGenitive.value
  }));

  const tUser = (key: string, vars?:Record<string, any>) => tUserType(key, type.value, { ...names.value, ...vars });
  const tUserFallback = (key: string, vars?:Record<string, any>) => tUserTypeFallBack(key, type.value, { ...names.value, ...vars });

  const userId = computed(() => user.value?.userId);

  const companyId = computed(() => user.value?.company?.id);
  const rootUserId = computed(() => user.value?.company?.rootUserId);

  return {
    hasRole,
    displayName,
    informalName,
    isFreelancer,
    isCustomer,
    isEmployee,
    isAdmin,
    isGuest,
    user,
    tUser,
    tUserFallback,
    companyId,
    rootUserId,
    type,
    userId,
    names
  };
};

export default useUser;

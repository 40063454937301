
import {
  computed, reactive, ref, defineComponent, Ref, ComputedRef, toRef
} from 'vue';
import { useStore } from 'vue2-helpers/vuex';
import useI18n from '@/mixins/useI18n';
import useMessengerState from '@/state/messenger/messengerState';
import BCXAvatar from '@/components/molecules/BCXAvatar.vue';
import BCXTextarea from '@/components/molecules/forms/BCXTextArea.vue';
import BCXCheckbox from '@/components/molecules/forms/BCXCheckbox.vue';
import X from '@/components/svg-components/X.vue';
import { useRoute } from 'vue2-helpers/vue-router';
import { Chat, ChatMessageType } from '@/models/Messenger';
import router from '@/router';
import { VoteReasonType } from '@/models/Voting';
import { Message } from '@/views/forum/models/Forum';
import useUser from '@/mixins/useUser';

interface DownvotePayload {
  voteType: 'DOWN';
  reason: VoteReasonType;
  downvoteReason?: string;
  link?: string;
}

export default defineComponent({
  name: 'Downvote',
  components: {
    BCXAvatar,
    BCXTextarea,
    BCXCheckbox,
    X,
  },
  props: {
    ratedUser: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const {
      createNewChat, sendMessage: messengerSendMessage, selectedChat
    } = useMessengerState();

    const ratedUser = toRef(props, 'ratedUser');

    const { locale } = useI18n();
    const { tUserFallback: t } = useUser('self');

    const route = useRoute();

    const user = computed(() => store.getters.user);
    const isMobileLayout = computed(() => store.getters['page/isMobileLayout']);
    const node = ref(null);

    const maxChars = ref(2000);

    const reasons = reactive({
      content: false,
      communicationStyle: false,
    });

    const voteReason: ComputedRef<VoteReasonType> = computed(() => {
      if (reasons.content && reasons.communicationStyle) {
        return 'BOTH';
      } if (reasons.content) {
        return 'CONTENT';
      } if (reasons.communicationStyle) {
        return 'COMMUNICATION';
      }
      return '';
    });

    function setReasonContent(value: boolean) {
      reasons.content = value;
    }
    function setReasonCommunicationStyle(value: boolean) {
      reasons.communicationStyle = value;
    }

    const downvoteMessage: Ref<string> = ref(`${t('voting.feedback.placeholder', { fname: props.ratedUser.firstname }) as string} `);
    const minMessageLength: Ref<number> = ref(40);

    const minCharsLeft:ComputedRef<string> = computed(() => t('voting.feedback.min-chars', { minChars: minMessageLength.value, count: downvoteMessage.value.length > minMessageLength.value ? 0 : minMessageLength.value - downvoteMessage.value.length }) as string);
    const showMinCharsLeft: ComputedRef<boolean> = computed(() => downvoteMessage.value.length < minMessageLength.value);

    const textAreaHeight: ComputedRef<number> = computed(() => {
      if (isMobileLayout.value) {
        return 194;
      }
      return 120;
    });

    const sendButtonDisabled = computed(() => (downvoteMessage.value.length < minMessageLength.value) || (voteReason.value.length === 0));

    const headerTitle = computed(() => t('voting.feedback.headline'));

    function closeModal() {
      store.dispatch('voting/resetVotingReporting');
    }

    function sendMessage() {
      const payload: DownvotePayload = {
        voteType: 'DOWN',
        reason: voteReason.value,
        downvoteReason: downvoteMessage.value,
        link: undefined,
      };

      createNewChat(ratedUser.value);

      const chatPayload: Chat = selectedChat.value;

      chatPayload.type = 'PRIVATE_MESSAGING';

      let routeTitle: string = store.state.projectForum.project.title;
      let markdownLink = '';
      let { href } = router.resolve({
        name: 'projectForumThread',
        params: { projectId: store.state.projectForum.project.id },
        hash: `#reply-${store.state.voting.businessObjectId}`
      });

      const projectOrThreadIncludesBusinessObjectId = computed(() => {
        const { businessObjectId } = store.state.voting;
        return store.state.projectForum.project.id === businessObjectId
        || store.state.forum.thread.initialMessage.messageId === businessObjectId
        || store.getters['projectForum/getThreadRepliesSorted'].map((reply: Message) => reply.messageId).includes(businessObjectId)
        || Object.keys(store.getters['forum/getThreadMessagesMap']).includes(businessObjectId);
      });

      if (['MESSAGE', 'PROJECT'].includes(store.state.voting.businessObjectType) && projectOrThreadIncludesBusinessObjectId.value) {
        if (route?.name === 'projectForumThread') {
          markdownLink = `[${routeTitle}](${href}})`;
          payload.link = href;
        }
        if (route?.name === 'thread') {
          routeTitle = store.state.forum.thread.title;
          href = router.resolve(
            {
              name: 'thread',
              params: {
                forumId: store.state.forum.thread.forumId,
                groupId: store.state.forum.thread.groupId,
                threadId: store.state.forum.thread.threadId
              },
              hash: `#reply-${store.state.voting.businessObjectId}`
            }
          ).href;
          markdownLink = `[${routeTitle}](${href})`;
          payload.link = href;
        }
      }
      const payloadMessage = `${t('voting.feedback.message-pre-text', { link: markdownLink }) as string} \n ${downvoteMessage.value}`;

      store.dispatch('voting/vote', payload).then((_response) => {
        messengerSendMessage(payloadMessage, ChatMessageType.PRIVATE_FEEDBACK_MESSAGING).then(() => {
          closeModal();
        });
      });
    }

    return {
      node,
      user,
      headerTitle,
      reasons,
      textAreaHeight,
      downvoteMessage,
      minCharsLeft,
      showMinCharsLeft,
      closeModal,
      setReasonContent,
      setReasonCommunicationStyle,
      sendButtonDisabled,
      sendMessage,
      maxChars,
      t,
    };
  },
});


import BCXUserBanner from '@/components/molecules/BCXUserBanner.vue';
import ProfileSection from '@/views/profile/components/ProfileSection.vue';
import useProfileState from '@/state/profile/profileState';
import { computed, defineComponent, toRefs } from 'vue';
import useI18n from '@/mixins/useI18n';

export default defineComponent({
  name: 'ProfileInvitedBy',
  components: {
    BCXUserBanner,
    ProfileSection,
  },
  props: {
    isEmployee: Boolean,
    isGuest: Boolean
  },
  setup(props) {
    const { t } = useI18n();
    const { profile, isQuickInfo } = useProfileState();
    const { isGuest, isEmployee } = toRefs(props);
    const user = computed(() => {
      if (isGuest.value) return profile.value?.company;
      return profile.value?.basic?.invitedBy;
    });

    const userId = computed(() => {
      if (user.value) {
        if (isGuest.value && 'rootUserId' in user.value) return user.value?.rootUserId ?? '';
        return user.value.id;
      }
      return null;
    });

    const headline = computed<string>(() => {
      if (isGuest.value) {
        return t('profile.common.guestOf').toString();
      }
      if (isEmployee.value) {
        return t('profile.common.employeeOf').toString();
      }
      return t('profile.common.invitedBy').toString();
    });
    return {
      user, userId, isQuickInfo, headline
    };
  },
});

import { computed, Ref } from 'vue';
import { BcxTableFilter } from '@/models/BcxTable';

export type BcxTableSearchFilterSortOptions = {
  searchCallback?: (searchTerm: string, obj:unknown) => boolean;
  filter?: BcxTableFilter;
  sortValueCallback?: (fieldName: string, obj:unknown) => string | number;

  states?: string[];
  sortField?: string;
  isAscending?: boolean;
  searchTerm?: string;
}

const useBcxTableSearchFilterSort = (options: BcxTableSearchFilterSortOptions, rows: Ref<Array<unknown>>) => {
  const searchedRows = computed(() => {
    if (options.searchCallback && options.searchTerm) {
      return rows.value.filter((row) => options?.searchCallback?.(options?.searchTerm ?? '', row));
    }
    return rows.value;
  });

  const searchedFilteredRows = computed(() => {
    if (options.filter && options.states && options.filter.stateCallback) {
      return searchedRows.value.filter((row) => options?.filter?.stateCallback(options?.states ?? [], row));
    }
    return searchedRows.value;
  });

  const searchedFilteredSortedRows = computed(() => {
    if (options.sortValueCallback && options.sortField) {
      return searchedFilteredRows.value.sort(
        (rowA, rowB) => {
          const aValue = options?.sortValueCallback?.(options?.sortField ?? '', rowA) ?? '';
          const bValue = options?.sortValueCallback?.(options?.sortField ?? '', rowB) ?? '';
          const comparison = (typeof aValue === 'number' && typeof bValue === 'number') ? (aValue - bValue) : aValue.toString().localeCompare(bValue.toString());
          return options.isAscending
            ? comparison : -comparison;
        }
      );
    }
    return searchedFilteredRows.value;
  });

  return {
    searchedFilteredSortedRows
  };
};

export default useBcxTableSearchFilterSort;


import BCExpansionPanel from '@/components/molecules/BCExpansionPanel.vue';
import Triangle from '@/components/svg-components/Triangle.vue';
import ThreadRepliesSkeleton from '@/views/forum/components/ThreadRepliesSkeleton.vue';
import { mdiChevronRight } from '@mdi/js';
import {
  computed, defineComponent, nextTick, onMounted, onUpdated, PropType, ref, toRefs, watch
} from 'vue';
import useUser from '@/mixins/useUser';
import { Message, MessagePost } from '@/views/forum/models/Forum';
import { useStore } from 'vue2-helpers/vuex';
import useProjectGuestState from '@/state/projectGuestState';
import useI18n from '@/mixins/useI18n';
import { useEventListener } from '@vueuse/core';
import { useRoute } from 'vue2-helpers/vue-router';
import CreateMessage from './CreateMessage.vue';
import ThreadReply from './ThreadReply.vue';

export default defineComponent({
  name: 'ThreadReplies',
  components: {
    'thread-reply': ThreadReply,
    'create-message': CreateMessage,
    'bc-expansion-panel': BCExpansionPanel,
    Triangle,
    ThreadRepliesSkeleton
  },
  props: {
    replies: {
      type: Array as PropType<Array<Message>>,
      default: () => []
    },
    threadCreator: {
      type: Object as PropType<any>,
      default: () => ({})
    },
    initialMessage: {
      type: Object,
      default: () => ({})
    },
    disableReply: {
      type: Boolean,
      default: false
    },
    forumId: {
      type: String,
      default: undefined
    },
    groupId: {
      type: String,
      default: undefined
    },
    threadId: {
      type: String,
      default: undefined
    },
    editMessageId: {
      type: String,
      default: ''
    },
    sortParam: {
      type: String,
      default: 'TIMESTAMP'
    },
    sortOrder: {
      type: String,
      default: 'DESC'
    },
    numberOfReplies: {
      type: Number,
      default: 0
    },
    threadSubscribed: {
      type: Boolean,
      default: false
    },
    parentType: {
      type: String,
      default: 'FORUM_THREAD'
    }
  },
  emits: [
    'post-message',
    'update-message',
    'edit-message',
    'sort-replies',
    'subscribe',
    'unsubscribe',
    'scrolled-to-bottom'
  ],
  setup(props, { emit }) {
    const store = useStore();
    const route = useRoute();
    const { tUserFallback: t } = useUser('self');
    const { tUserFallback: tUser } = useUser('self');
    const user = computed(() => store.getters['user']);
    const project = computed(() => store.state.projectForum.project);
    const createMessageVisible = ref(false);
    const scrolledToMessage = ref(false);
    const settings = ref({});
    const watchThread = ref(false);

    const {
      threadCreator, numberOfReplies, parentType, threadSubscribed, forumId, threadId, initialMessage
    } = toRefs(props);

    watch(() => threadSubscribed.value, (newValue) => {
      watchThread.value = newValue;
    });

    const labelReplyCount = computed(() => {
      if (numberOfReplies.value === 1) {
        return `${numberOfReplies.value} ${t('forum.labels.replies.singular')}`;
      }
      return `${numberOfReplies.value} ${t('forum.labels.replies.plural')}`;
    });
    const noForumReplies = computed(() => numberOfReplies.value === 0);
    const isUserCreator = computed(() => store.getters['user'].userId === threadCreator.value.userId);
    const isWatchSwitchDisabled = computed(() => parentType.value === 'PROJECT_THREAD' && isUserCreator.value);
    const watchThreadTranslationKey = computed(() => {
      if (parentType.value === 'PROJECT_THREAD') {
        return 'project-forum_USERTYPE.labels.watch-project';
      }
      return 'forum.labels.watch-thread';
    });
    const isTestProject = computed(() => project.value.metaDataProject.testFlag || false);

    const hasReplies = (reply: Message): boolean => (reply?.subMessages?.length || 0) > 0;
    const hasOPReply = (replies: Array<Message>): boolean => replies.some((reply) => reply.creator.userId === threadCreator.value.userId);
    const toggleCreateMessageVisible = (): void => {
      createMessageVisible.value = !createMessageVisible.value;
    };
    const { projectGuestList } = useProjectGuestState(project.value?.company.id);
    const isOP = (userId: string): boolean => threadCreator.value.userId === userId
      || projectGuestList.value.some((guest) => guest.id === userId);

    useEventListener('scroll', () => {
      const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight - 200;
      if (bottomOfWindow) {
        emit('scrolled-to-bottom');
      }
    });

    const expansionPanelStateChange = (state: string, id: string) => {
      const el = document.querySelector(`#inner-replies-${id}`);
      if (el) {
        switch (state) {
          case 'open':
            el.classList.add('expansion-panel--open');
            break;
          case 'close':
          default:
            el.classList.remove('expansion-panel--open');
            break;
        }
      }
    };

    const getReplyText = (replyCount: number): string => {
      if (replyCount === 1) {
        return `${replyCount} ${t('forum.labels.replies.singular') as string}`;
      }
      return `${replyCount} ${t('forum.labels.replies.plural')}`;
    };

    const postMessage = (postMessage: MessagePost) => {
      emit('post-message', postMessage);
      createMessageVisible.value = false;
    };

    const firstReply = (message: string) => {
      const messageObject: MessagePost = {
        forumId: forumId.value,
        forumThreadId: threadId.value,
        replyToUserId: initialMessage.value.creator.userId,
        replyToMessageId: initialMessage.value.messageId,
        messageContent: message
      };
      postMessage(messageObject);
    };

    const updateMessage = (postMessage: MessagePost, messageCount: string) => {
      emit('update-message', postMessage, messageCount);
    };

    const editMessage = (editMessageId: string) => {
      emit('edit-message', editMessageId);
    };

    const sort = (sortParam: string) => {
      let sortOrder = null;
      if (sortParam === props.sortParam && props.sortOrder === 'ASC') {
        sortOrder = 'DESC';
      } else if (sortParam === props.sortParam && props.sortOrder === 'DESC') {
        sortOrder = 'ASC';
      } else if (sortParam === 'VOTES') {
        sortOrder = 'DESC';
      } else {
        sortOrder = 'ASC';
      }
      emit('sort-replies', sortParam, sortOrder);
    };

    const isSortActive = (sort: string) => (sort === props.sortParam ? 'active' : '');
    const toggleWatchThread = () => {
      if (watchThread.value) {
        emit('subscribe');
      } else {
        emit('unsubscribe');
      }
    };

    onMounted(() => {
      nextTick(() => {
        if (threadSubscribed.value || isUserCreator.value) {
          watchThread.value = true;
        }
      });
    });

    onUpdated(() => {
      const { hash } = route;
      nextTick(() => {
        if (hash && !scrolledToMessage.value) {
          const element = document.querySelector(hash);
          const messageElement = element?.querySelector('.thread-message');
          if (element && messageElement) {
            messageElement.classList.add('scroll-highlight');
            setTimeout(() => {
              messageElement.classList.remove('scroll-highlight');
            }, 1250);
            setTimeout(() => {
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
              });
            }, 100);
            scrolledToMessage.value = true;
          }
        }
      });
    });

    return {
      user,
      project,
      isOP,
      tUser,
      hasReplies,
      hasOPReply,
      mdiChevronRight,
      createMessageVisible,
      toggleCreateMessageVisible,
      scrolledToMessage,
      settings,
      watchThread,
      labelReplyCount,
      noForumReplies,
      isUserCreator,
      isWatchSwitchDisabled,
      watchThreadTranslationKey,
      isTestProject,
      expansionPanelStateChange,
      getReplyText,
      postMessage,
      firstReply,
      updateMessage,
      editMessage,
      sort,
      isSortActive,
      toggleWatchThread
    };
  }
});
